import React, { useContext } from 'react';
import Container from '../components/Container';
import { Box } from '@chakra-ui/react';
import { FirebaseContext } from '../FirebaseContext';
import GoldenBookCard from '../components/GoldenBookCard';
import { headers } from '../headers';
import Header from '../components/Header';

export default function GoldenBook() {
  const { goldenBooks } = useContext(FirebaseContext);

  return (
    <Container>
      <Box
        display="flex"
        flexDir="row"
        flexWrap="wrap"
        gap={8}
        mt={8}
        justifyContent="center"
      >
        <GoldenBookCard key={goldenBooks.length + 1} createMode />
        {goldenBooks.map((goldenbook, index) => (
          <>
            <GoldenBookCard key={index + 1} goldenbook={goldenbook} />
          </>
        ))}
      </Box>
      <Box mt={16} />
    </Container>
  );
}

export function Head() {
  return <Header {...headers.goldenbook} />;
}
