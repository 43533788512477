import React from 'react';
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
} from '@chakra-ui/react';

const Modal = ({ title, content, isOpen, onClose }) => (
  <ChakraModal isOpen={isOpen} onClose={onClose} isCentered>
    <ModalOverlay />
    <ModalHeader>{title}</ModalHeader>
    <ModalContent>
      <ModalCloseButton />
      <ModalBody mt={4} mb={4}>
        <Flex justifyContent="center">{content}</Flex>
      </ModalBody>

      <ModalFooter>
        <Flex justifyContent="center" w="100%">
          <Button colorScheme="test" onClick={onClose}>
            C'est noté !
          </Button>
        </Flex>
      </ModalFooter>
    </ModalContent>
  </ChakraModal>
);

export default Modal;
