import React, { useState, useContext } from 'react';
import {
  Box,
  Flex,
  Image,
  Text,
  Button,
  Input,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import Card from './Card';
import { FirebaseContext } from '../FirebaseContext';
import { IcUpload } from '../assets';
import ReCAPTCHA from 'react-google-recaptcha';
import { capitalize, isDev } from '../utils';
import Modal from './Modal';

export default function GoldenBookCard({ goldenbook, createMode = false }) {
  const [picture, setPicture] = useState(null);
  const [dogName, setDogName] = useState(null);
  const [email, setEmail] = useState(null);
  const [description, setDescription] = useState(null);
  const { addGoldenBook } = useContext(FirebaseContext);
  const recaptchaRef = React.createRef();
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Card w={250} h={500}>
      <Flex direction="column" alignItems="center">
        <Text></Text>
        <label style={{ width: 250, position: 'relative', display: 'block' }}>
          <Image
            src={!createMode ? goldenbook.url : picture?.url ?? IcUpload}
            w="250px"
            h="200px"
            objectFit={picture?.url || !createMode ? 'cover' : 'none'}
            borderTopRadius={'xl'}
            objectPosition={
              goldenbook?.objectPosition ? goldenbook.objectPosition : '50% 50%'
            }
          />
          <Input
            type="file"
            onChange={e => {
              const files = e.target.files;
              if (files.length > 0) {
                setPicture({
                  file: files[0],
                  url: URL.createObjectURL(files[0]),
                });
              }
            }}
            display="none"
            disabled={!createMode}
          />
          {createMode && !picture?.url && (
            <Box w="100%" position="absolute" top="80%">
              <Text textColor="#C4DEB5" fontWeight="bold" textAlign="center">
                Ajouter une photo*
              </Text>
            </Box>
          )}
        </label>
        <Box paddingStart={5} paddingEnd={5} paddingBottom={5} w="100%">
          <Box
            fontWeight={'semibold'}
            mt={2}
            textAlign="center"
            color="#B5851F"
          >
            {!createMode && (
              <Text fontWeight="bold">{capitalize(goldenbook.dogName)}</Text>
            )}
            {createMode && (
              <Flex flexDir="column" gap={4}>
                <Input
                  type="email"
                  name="email"
                  onChange={e => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  backgroundColor="#f1f1f1"
                  placeholder="Votre email*"
                  _placeholder={{ color: 'gray.800' }}
                  flex={1}
                  pt={2}
                  pb={2}
                />
                <Input
                  type="text"
                  name="dogName"
                  onChange={e => {
                    setDogName(e.target.value);
                  }}
                  value={dogName}
                  backgroundColor="#f1f1f1"
                  _placeholder={{ color: 'gray.800' }}
                  placeholder="Nom de votre chien*"
                  flex={1}
                  pt={2}
                  pb={2}
                />
              </Flex>
            )}
          </Box>
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            flexWrap
            gap={2}
            mt={4}
            w="100%"
          >
            {!createMode && (
              <Text noOfLines={10}>{goldenbook.description}</Text>
            )}
            {createMode && (
              <Textarea
                name="description"
                value={description}
                onChange={e => {
                  setDescription(e.target.value);
                }}
                backgroundColor="#f1f1f1"
                _placeholder={{ color: 'gray.800' }}
                placeholder="Partagez-nous votre expérience*"
                maxLength={300}
                noOfLines={10}
              />
            )}
            {createMode && (
              <>
                <Button
                  type="button"
                  colorScheme="test"
                  disabled={
                    !picture?.url ||
                    !dogName ||
                    !description ||
                    !email ||
                    loading
                  }
                  onClick={async () => {
                    setLoading(true);
                    const token = isDev ? true : recaptchaRef.current.execute();
                    if (token) {
                      const result = await addGoldenBook(
                        picture.file,
                        dogName,
                        description,
                        email
                      );
                      if (result) {
                        setPicture(null);
                        setDogName('');
                        setDescription('');
                        setEmail('');
                      }
                      recaptchaRef.current?.reset();
                      onOpen();
                    }
                    setLoading(false);
                  }}
                >
                  Soumettre la publication
                </Button>
                <Text alignSelf="start">* champs obligatoires</Text>
                {!isDev && (
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LeaSrohAAAAAJAPulqjNpIjL4bWr3OW-d-bMrY6"
                    size="invisible"
                  />
                )}
              </>
            )}
          </Flex>
        </Box>
      </Flex>
      {
        <Modal
          content="La demande a bien été envoyée 😊"
          isOpen={isOpen}
          onClose={onClose}
        />
      }
    </Card>
  );
}
