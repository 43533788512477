import React from 'react';
import { Box } from '@chakra-ui/react';

export default function Card({ w, h, children }) {
  return (
    <Box shadow="base" borderRadius={'xl'} bg="white" w={w} h={h}>
      {children}
    </Box>
  );
}
